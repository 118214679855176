<template>
  <div class="brand-nav border-b-2">
      <router-link
        to="/homepage"
        data-cy="home"
      >
          <span class="text-white inline-block font-bold bg-theme-dark p-4 mr-4 hover:underline">
            {{
              $store.state.brand.charAt(0).toUpperCase() + $store.state.brand.slice(1)
            }}
          </span>
      </router-link
    >
    <router-link
      to="/devices"
      data-cy="devices-link"
      class="mr-4 brand-nav-item hover:underline"
      >Devices</router-link
    >
    <router-link
      to="/sessions"
      data-cy="sessions-link"
      class="mr-4 brand-nav-item hover:underline"
      >Sessions</router-link
    >
    <router-link
      v-if="$store.state.brand.includes('Oral-B')"
      to="/consumables"
      data-cy="consumables-link"
      class="mr-4 brand-nav-item hover:underline"
      >Consumables</router-link
    >
    <router-link
      to="/logs"
      data-cy="logs-link"
      class="mr-4 brand-nav-item hover:underline"
      >Logs</router-link
    >
     <router-link
      to="/advanced"
      data-cy="advanced-link"
      class="mr-4 brand-nav-item hover:underline"
      >Advanced</router-link
    >
    <router-link
      v-if="$store.state.brand.includes('Labs')"
      to="/integrations"
      data-cy="integrations-link"
      class="mr-4 brand-nav-item hover:underline"
      >Integrations</router-link
    >
    <router-link
      to="/validations"
      data-cy="validatoins-link"
      class="mr-4 brand-nav-item hover:underline"
      >Validations</router-link
    >
    <a
      href="https://lightyear.zendesk.com/hc/en-us"
      data-cy="support-link"
      class="mr-4 brand-nav-item hover:underline"
      target="_blank"
      >Support</a
    >
    <Download :download-data="this.$store.getters.downloadSchema" />
    <JwtButton :button-text="this.jwtButtonText" :token="this.jwtToken" />
    <JwtButton :button-text="this.accessButtonText" :token="this.accessToken"/>
    <router-link
      to="/logout"
      data-cy="logout-link"
      class="mr-4 brand-nav-item logout-link hover:underline"
      >Logout of: {{ $store.state.user.attributes.email }}</router-link
    >
  </div>
</template>

<script>
import Download from './Download';
import JwtButton from './JwtButton';

export default {
  data () {
    return {
      jwtButtonText: 'JWT Token',
      jwtToken: this.$store.state.user.idToken.jwtToken,
      accessButtonText: 'Access Token',
      accessToken: this.$store.state.user.accessToken.jwtToken
    };
  },
  components: {
    Download,
    JwtButton
  }
};
</script>

<style lang="scss">
.router-link-active {
  background: none;
  color: #4285f4;
}

.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  color: #fff;
}

.brand-nav {
  position: fixed;
  top: 80px;
  z-index: 50;
  background: #f4f6fa;
  width: 100%;
}

.logout-link {
  padding-right: 48px;
  float: right;
  margin-top: 18px;
}
</style>
