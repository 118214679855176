<template>
  <a
    class="download-button text-white"
    :href="convertData"
    download="Schema.json"
  >
    Download Schema
  </a>
</template>

<script>
import brandConfig from '@/util/brandConfigs';

export default {
  props: {
    downloadData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      config: brandConfig.agnostic.schema
    };
  },
  created () {
    this.$store.dispatch('getSchemaQuery', {
      query: this.config.query
    });
  },
  computed: {
    convertData () {
      const dData = JSON.stringify(this.downloadData, null, 2);
      const blob = new Blob([dData], { type: 'application/json' });
      return window.URL.createObjectURL(blob);
    }
  }
};
</script>

<style>
.download-button {
  background: #4285f4;
  border-radius: 0px;
  min-width: 153px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 14px 10px;
  letter-spacing: 1.1px;
  border: none;
  text-transform: uppercase;
}
</style>
